<template>
  <v-container style="background-color: white; height: 100vh">
    <v-dialog
        v-model="dialog"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <BackArrow style="position: initial"/>
        <v-form
          id="perfilUpdateForm"
          ref="perfilUpdateForm"
          @submit.prevent="atualizar()"
        >
          <v-row justify="center" >
            <v-col cols="11">
              <h1 class="generic-title mt-10 mb-5">Informações pessoais</h1>
              <avatar-upload
                class="text-center pb-5"
                size="115"
              />
              <v-text-field
                class="pb-0"
                :disabled="verificarPermicaoAtualigacao()"
                label="Nome"
                v-model="updateAttributesUser.name"
              />
              <v-text-field
                class="pb-3"
                type="number"
                :disabled="!editing || desabilitarCPF"
                label="CPF"
                v-model="updateAttributesUser.cpf"
                :rules="cpfRules"
              />
              <date-picker
                :show-prepend-icon-date="false"
                label="Data de Nascimento"
                :disabled="verificarPermicaoAtualigacao()"
                :date-prop="updateAttributesUser.birthdayDate"
                v-on:updateDate="updateDate"
              />
              <v-select
                class="pt-4"
                :disabled="verificarPermicaoAtualigacao()"
                label="Estado Civil"
                v-model="updateAttributesUser.maritalSatus"
                :items="estadoCivil"
              />
              <v-select
                :disabled="verificarPermicaoAtualigacao()"
                label="Gênero"
                v-model="updateAttributesUser.gender"
                :items="genero"
              />
              <p
                  class="pt-5 pb-0 mb-0 generic-title"
              >Perfil de Saúde</p>
              <v-select
                class="pb-5"
                :menu-props="{ top: true, offsetY: true }"
                single-line
                v-model="updateAttributesUser.healthChallenge"
                :items="desafios"
                chips
                label="Perfil de Saúde"
                multiple
                :disabled="!editing"
              ></v-select>
            </v-col>
          </v-row>
            <v-btn
              v-if="!editing"
              fab
              bottom
              right
              fixed
              color="pink"
              dark
              @click="editing = true"
            >
              <v-icon>fas fa-pencil-alt</v-icon>
            </v-btn>
          <div
            v-else
            class="pb-10 "
          >
            <generic-btn
                :btn-props="{ block: true , large: true}"
                :on-click="() => dialog = true"
                v-bind="attrs"
                v-on="on"
            >
              Atualizar
            </generic-btn>
          </div>
        </v-form>
      </template>
      <v-card class="px-4 pb-16" style="background-color: #EEEDF7">
        <v-btn
            class="pt-10 pb-4"
            icon
            dark
            @click="dialog = false"
        >
          <v-icon class="pt-2 pl-2" size="40" color="#292867">mdi-close</v-icon>
        </v-btn>
        <v-card-title
          class="pb-8 pt-16 generic-title justify-center card-text"
          >
          Importante
        </v-card-title>
        <v-card-text
          class="text-alert pb-8 text-center"
          >
          Ao editar o seu Perfil, você estará alterando o protocolo de saúde aplicado.
        </v-card-text>
        <v-card-actions
          class="pb-16 pt-10"
          style=""
        >
          <v-spacer></v-spacer>
          <generic-btn
              :btn-props="{ block: true , large: true}"
              :on-click="() => atualizar()"
          >
            Estou ciente
          </generic-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AvatarUpload from "../../components/upload/AvatarUpload";
import BackArrow from "@/components/covid/back-arrow";
import GenericBtn from "@/components/generic-btn/index.vue";
import HealthChallengeService from "@/services/healthcare/HealthChallengeService";
import UserHealthChallengeService from "@/services/healthcare/UserHealthChallengeService";
import { updateUser } from "../../common/securityserver";
import $util from "../../common/util";
import DatePicker from '../../components/date-picker/index.vue';

export default {
  data() {
    return {
      updateAttributesUser: {
        name: null,
        email: null,
        cpf: null,
        birthdayDate: null,
        maritalSatus: null,
        gender: null,
      },
      dialog: false,
      desabilitarCPF: false,
      busy: false,
      editing: false,
      user: {},
      desafios: [],
      genero: ["Masculino","Feminino","Outro"],
      estadoCivil: ['Solteiro','Casado','Separado','Divorciado','Viuvo'],
      cpfRules:[
        v => !v || $util.validateCPF(v) || 'CPF inválido',
      ],
    };
  },
  components: {
    BackArrow,
    GenericBtn,
    AvatarUpload,
    DatePicker
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "updateMenuState", "toogleMainDialog"]),

    updateDate(date){
        this.updateAttributesUser.birthdayDate = date;
    },
    verificarPermicaoAtualigacao(){
      return !this.editing || this.$auth.user().hasCompanyConnection;
    },
    initializar() {
      this.user = this.$auth.user();
      this.desabilitarCPF = this.user.cpf !== null;
      this.updateAttributesUser = {
        id: this.user.id,
        name: this.user.name,
        cpf: this.user.cpf,
        birthdayDate: this.user.birthdayDate,
        maritalSatus: this.maritalStatus(),
        gender: this.gender(),
        healthChallenge: [],
      }
    },
    atualizar(){
      if (!this.$refs.perfilUpdateForm.validate()) {
        setTimeout(this.$refs.perfilUpdateForm.resetValidation, 3000);
        return;
      }
      if(this.updateAttributesUser.maritalSatus) {
        this.updateAttributesUser.maritalSatus = this.updateAttributesUser.maritalSatus.toUpperCase()
      }
      if(this.updateAttributesUser.gender) {
        this.updateAttributesUser.gender = this.updateAttributesUser.gender.toUpperCase()
      }
      this.loading(true);
      updateUser(this.updateAttributesUser)
        .then(response => {
          if (response.status  === 200) {
            this.$auth.setUser(response.data);
            this.user = response.data
            this.desabilitarCPF = this.updateAttributesUser.cpf !== null;
            this.updateAttributesUser.maritalSatus = this.maritalStatus();
            this.updateAttributesUser.gender = this.gender();
            this.showmsg({ text: "Perfil atualizado com Sucesso", type: "success" });
          } else {
            this.showmsg({ text: "Erro ao atualizar perfil!", type: "error" });
          }
          this.saveDesafios();

        })
        .catch(() =>
          this.showmsg({ text: "Erro ao atualizar perfil!", type: "error" })
        ).finally(() => {
          this.loading(false);
          this.editing = false;
          this.dialog = false;
        });

    },
    maritalStatus() {
      switch (this.user.maritalSatus) {
        case "SOLTEIRO":
          return "Solteiro";
        case "CASADO":
          return "Casado";
        case "SEPARADO":
          return "Separado";
        case "DIVORCIADO":
          return "Divorciado";
        case "VIUVO":
          return "Viúvo";
        default:
          return null;
      }
    },
    gender() {
      switch(this.user.gender) {
        case "MASCULINO":
          return "Masculino";
        case "FEMININO":
          return "Feminino";
        case "OUTRO":
          return "Outro";
        default:
          return null;
      }
    },
    link(name) {
      this.$router.push({ name: name });
    },
    logout: function () {
      this.$auth.logout();
    },
    loadDesafioDeSaude() {
      this._health_challenge.findAll()
          .then((_res) => {
            if (_res && _res.data && _res.data.length > 0) {
              _res.data.forEach((element) => {
                this.desafios.push({
                  text: element.name,
                  value: element.code,
                });
              });
            }
          })
        .catch((error)=>{})
        .finally(()=>{ this.loading(false)});

      this._user_health_challenge.findAllByUser(this.user.id)
        .then((_res) => {
          if (_res && _res.data && _res.data.length > 0) {

            let filtrarDesafiosSelecionados = _res.data.filter(function (el) {
              return el.value;
            });
            this.updateAttributesUser.healthChallenge = filtrarDesafiosSelecionados.map(value => value.healthChallenge.code);
          }
        })
        .catch((error)=>{})
        .finally(()=>{});
    },
    saveDesafios() {
      let userChallenges = {
        healthChallengeCodes:
          this.updateAttributesUser.healthChallenge,
        userIds: [
          this.user.id
        ],
        value: true
      }
      this._user_health_challenge.saveAll(userChallenges)
          .then((_res) => {
          })
          .catch((error)=>{})
          .finally(()=>{});
    }
  },
  created() {
    this.loading(true);
    this._health_challenge = new HealthChallengeService();
    this._user_health_challenge = new UserHealthChallengeService();
    this.initializar();
    this.loadDesafioDeSaude();
  },
  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function () {
        return this.menu.rigth;
      },
      set: function (value) {
        this.updateMenuState({ rigth: value });
      },
    },

  },

};
</script>

<style scoped>
.card-text{
  font-size: 28px !important;
  color: #292867 !important;
  font-weight: bold !important;
  font-family: 'Open Sans' !important;
}
.text-alert{
  color: #292867 !important;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Open Sans' !important;
}

</style>
